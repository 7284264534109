import React, { useEffect } from 'react';
import { motion } from 'framer-motion';
import { Link, useLocation } from 'react-router-dom';
import { NavLink } from 'react-router-dom';

// import OrbButton from 'elements/OrbButton.js';
// import MultiLevelMenu from 'elements/MultiLevelMenu.js';

import DynamicIcon from 'elements/DynamicIcon';
import AuthIndicator from 'elements/AuthIndicator.js';

// import { getWebsite } from 'utils/WebsiteUtils.js';
// import { getValueFromParameterMap } from 'cms/NBossCMS.js';

export default function MenuSlider(props) {
    let location = useLocation();

    useEffect(() => {
        if (props.onSliderClose())
            props.onSliderClose()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location]);

    return (
        <motion.div
            className={'fixed w-full h-full z-50 ' + (props.className || '')}
            initial={props.open ? 'visible' : 'hidden'}
            animate={props.open ? 'visible' : 'hidden'}
            variants={{ visible: { opacity: 1, display: 'flex' }, hidden: { opacity: 0.9999, transitionEnd: { display: 'none' } } }}>

            <motion.div
                className='absolute w-full h-full bg-tv-yellow'
                initial={props.open ? 'visible' : 'hidden'}
                animate={props.open ? 'visible' : 'hidden'}
                variants={{ visible: { opacity: 0.5 }, hidden: { opacity: 0 } }}
                onClick={() => props.onSliderClose()} />

            <div className='absolute right-0 h-full w-3/4 max-w-sm filter'>

                <motion.div
                    className='bg-tv-purple h-full flex flex-col gap-6 py-6 rounded-l-3xl'
                    initial={props.open ? 'visible' : 'hidden'}
                    animate={props.open ? 'visible' : 'hidden'}
                    style={{ originX: 1, originY: 0.5, transformPerspective: 1250 }}
                    transition={{ duration: 0.35 }}
                    variants={{ visible: { rotateY: 0, display: 'flex' }, hidden: { rotateY: -90, transitionEnd: { display: 'none' } } }}>

                    <div className='relative w-full flex px-6' >

                        <Link to='/' className='flex flex-1'>
                            {/* <img className='w-11 mt-1' alt='logo' src='assets/images/logo/shield-text-top-fill-white.svg' /> */}
                            <Heading />
                            {/* <img className='h-full' alt='logo' src='assets/images/logo/shield-text-btm-outline-white.svg' /> */}
                        </Link>

                        <CloseButton onClick={() => props.onSliderClose()} />

                        {/* <OrbButton
                            className='w-9 h-9'
                            data={{ iconName: 'FaTimes', iconClassName: 'text-lg text-white', backgroundClassName: 'bg-white bg-opacity-30' }}
                            onClick={() => props.onSliderClose()} /> */}
                    </div>

                    {/* <MultiLevelMenu
                        className='w-full flex-1 overflow-y-auto overflow-x-hidden px-6'
                        data={getValueFromParameterMap(getWebsite().globalContent, 'menu')}
                        baseLevel={0}
                        firstLevelKey='itemList'
                        activeClassName='font-semibold'
                        baseClassName='text-white font-thin tracking-wider'
                        mainClassName='text-sm uppercase'
                        subClassName='text-xs'
                        orbClassName='bg-white'
                        iconClassName='text-tv-red dark:text-white'
                        onChildExistClick={() => console.log("Van gyereke.")}
                        onNoChildExistClick={() => props.onSliderClose()} /> */}

                    <AuthIndicator
                        icon
                        containerClassName='flex flex-col gap-4'
                        loginClassName='mx-6 px-4 py-3 text-center rounded-full border-white border border-opacity-50 text-white font-bold uppercase tracking-wide text-sm'
                        kauClassName='mx-6 px-4 py-3 text-center rounded-full border-white border border-opacity-50 text-white font-bold uppercase tracking-wide text-sm'
                    />

                    <div className='w-full flex-1 flex flex-col gap-3 px-6'>
                        {props.menuItems && props.menuItems.itemList.map((item, index) => <MenuItem key={index} data={item} />)}
                    </div>

                    <LogoList />

                    <div className='w-full flex-col items-center justify-center text-center text-white text-xs font-thin leading-4 px-6'>
                        <span>2022 © Terézváros Önkormányzata<br />Minden jog fenntartva.</span>
                    </div>

                </motion.div>

            </div>

        </motion.div >
    );
}

function Heading(props) {
    return (
        <div className='flex flex-col text-white font-extrabold tracking-wide xl:tracking-wider'>
            <div className='text-4xl'>Közösségi</div>
            <div className='text-3xl'>költségvetés <span className='text-tv-yellow'>2025</span></div>
            {/* <div className='flex sm:hidden text-tv-purple font-extrabold text-7xl mt-1'>2022</div> */}
        </div>
    );
}

function LogoList(props) {
    return (
        <div className={'flex flex-row gap-6 items-center justify-center ' + (props.className || '')}>
            <a href='https://terezvaros.hu/' className='transition hover:opacity-70'><img className='h-12' alt='logo' src='assets/images/logo/shield-text-top-fill-white.svg' /></a>
            <a href='https://transparency.hu/' className='transition hover:opacity-70'><img className='h-10' alt='logo' src='assets/images/ti-logo-white.svg' /></a>
        </div>
    );
}

function MenuItem(props) {
    const commonClassName = 'w-full rounded-full pl-4 pr-2 py-2 text-sm font-bold uppercase tracking-wide flex flex-row items-center';
    const activeClassName = 'bg-tv-yellow text-tv-purple';
    const passiveClassName = 'opacity-40 border-white border text-white'
    // const labelClassName = 'bg-tv-yellow    whitespace-nowrap transition';

    if (props.data.targetType !== 'None') {
        if (props.data.targetType !== 'ExternalUrl')
            return (
                <NavLink to={props.data.path} className={commonClassName + ' ' + (props.data.targetType !== 'None' ? activeClassName : passiveClassName)}>
                    <MenuItemChildren data={props.data} />
                </NavLink>
            );
        else
            return (
                <a href={props.data.value} className={commonClassName + ' ' + (props.data.targetType !== 'None' ? activeClassName : passiveClassName)}>
                    <MenuItemChildren data={props.data} />
                </a>
            );
    } else {
        return (
            <div className={commonClassName + ' ' + (props.data.targetType !== 'None' ? activeClassName : passiveClassName)}>
                <MenuItemChildren data={props.data} />
            </div>
        );
    }
}

function MenuItemChildren(props) {
    return (
        <>
            <div className={'flex-1 ' + (props.data.title.length > 20 ? '' : '')}>{props.data.title}</div>
            <div className={'h-6 aspect-square rounded-full flex items-center justify-center ' + (props.data.targetType !== 'None' ? 'bg-tv-purple' : 'border border-white')}>
                <DynamicIcon className='text-white text-xs ml-[1px]' iconName='FaChevronRight' />
            </div>
        </>
    );
}

function CloseButton(props) {
    return (
        <button className={'w-10 h-10 border border-white border-opacity-10 rounded-xl mt-1.5 flex items-center justify-center ' + (props.className || '')} onClick={() => props.onClick()}>
            <DynamicIcon className='text-white text-xl' iconName='FaTimes' />
        </button>
    );
}